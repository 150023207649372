import type { TippyProps } from '@tippyjs/react';

import Tippy from '@tippyjs/react';
import React, { forwardRef, useState } from 'react';

/**
 * A wrapper component around the base Tippy component that will only render the content when the tooltip is mounted (visible).
 * It should only be used in performance-sensitive cases where the content is expensive to render.
 *
 * Derived from https://github.com/atomiks/tippyjs-react#lazy-mounting examples
 */
export const LazyTippy = forwardRef((props: TippyProps, ref: React.Ref<Element>) => {
  const [mounted, setMounted] = useState(false);

  const lazyPlugin = {
    fn: () => ({
      onMount: () => setMounted(true),
      onHidden: () => setMounted(false),
    }),
  };

  const computedProps = { ...props };

  computedProps.plugins = [lazyPlugin, ...(props.plugins || [])];

  if (props.render) {
    const render = props.render; // let TypeScript safely derive that render is not undefined
    computedProps.render = (...args) => (mounted ? render(...args) : '');
  } else {
    computedProps.content = mounted ? props.content : '';
  }

  return <Tippy {...computedProps} ref={ref} />;
});

LazyTippy.displayName = 'LazyTippy';

export default LazyTippy;
