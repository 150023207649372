import { getSupportedLanguages } from '@readme/oas-to-snippet/languages';
import { uppercase } from '@readme/syntax-highlighter';
import copy from 'clipboard-copy';
import httpsnippetClientAPIPlugin from 'httpsnippet-client-api';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import useReadmeApi from '@core/hooks/deprecated/useReadmeApi';
import classy from '@core/utils/classy';
import spreadLineNumbers from '@core/utils/spreadLineNumbers';

import useApiActions from '@routes/Tutorials/useApiActions';

import Method from '@ui/API/Method';
import Button from '@ui/Button';
import Dropdown from '@ui/Dropdown';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import Menu from '@ui/Menu';
import MenuDivider from '@ui/Menu/Divider';
import MenuHeader from '@ui/Menu/Header';
import MenuItem from '@ui/Menu/Item';

import { PermissionContext } from '../../PermissionContext';
import { ApiEndpointProp } from '../../proptypes/apiEndpoints';
import { StepSnippetProp, CodeOptionProp, EndpointProp } from '../../proptypes/tutorials';

import classes from './style.module.scss';

const syntaxHighlighter = typeof window !== 'undefined' ? require('@readme/syntax-highlighter/dist').default : () => {};

const LanguageDropdown = ({ languageOpts, index, ...props }) => {
  const maxTabCount = 4;

  const handleTabCreate = value => {
    try {
      const { language, highlightedSyntax } = JSON.parse(value);
      if (language) {
        props.handleTabCreate({ language, highlightedSyntax });
      }
    } catch (e) {
      // Do nothing
    }
  };

  return (
    <React.Fragment>
      {index < maxTabCount && (
        <Dropdown className={classes['TutorialEditor-Nav-Plus']}>
          <Button bem={{ white_text: true }}>
            <i className="fa fa-plus-circle" />
          </Button>
          <div className={classes['TutorialEditor-Nav-Plus-ClearWrapper']}>
            <Menu className={classes['TutorialEditor-Nav-Plus-ClearWrapper-Box']} theme="dark">
              {languageOpts.map(({ value, label }, idx) => (
                <MenuItem key={`tab-menu-language-${idx}`} onClick={() => handleTabCreate(value)}>
                  {label}
                </MenuItem>
              ))}
            </Menu>
          </div>
        </Dropdown>
      )}
    </React.Fragment>
  );
};

LanguageDropdown.propTypes = {
  handleTabCreate: PropTypes.func,
  index: PropTypes.number,
  languageOpts: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
};

const SnippetLanguageMenu = ({ codeOptions, languageOpts, readOnly, setSelectedTab, selectedTab, ...props }) => {
  const handleTabCreate = ({ language, highlightedSyntax }) => {
    // Basic scaffold for a new tab
    props.handleTabCreate({
      code: '',
      language,
      highlightedSyntax,
      name: uppercase(language),
    });
  };

  const tutorialTabClasses = index =>
    classy(classes['TutorialEditor-Nav-Tab'], index === selectedTab && classes['TutorialEditor-Nav-Tab_active']);

  return (
    <Flex>
      {!!codeOptions &&
        codeOptions.map((opt, index) => (
          <Button
            key={index}
            bem={{ white_text: true }}
            className={`${tutorialTabClasses(index)}`}
            onClick={() => setSelectedTab(index)}
          >
            {opt.name}
          </Button>
        ))}
      {!readOnly && (
        <LanguageDropdown
          handleTabCreate={handleTabCreate}
          index={codeOptions.length - 1}
          languageOpts={languageOpts}
        />
      )}
    </Flex>
  );
};

SnippetLanguageMenu.propTypes = {
  codeOptions: PropTypes.arrayOf(CodeOptionProp),
  handleTabCreate: PropTypes.func,
  languageOpts: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  readOnly: PropTypes.bool,
  selectedTab: PropTypes.number,
  setSelectedTab: PropTypes.func,
};

const EndpointSelector = ({
  baseUrl,
  endpointOnboarding,
  endpoints,
  codeOptions,
  selectedEndpoint,
  referenceEnabled,
  readOnly,
  requestDataRefresh,
  handleSnippetSelection,
  clearSnippetSelection,
}) => {
  const { response, initRequest } = useReadmeApi(baseUrl);
  const ApiActions = useApiActions();

  useEffect(() => {
    if (response.success) requestDataRefresh();
  }, [response]); // eslint-disable-line react-hooks/exhaustive-deps

  return !readOnly || (referenceEnabled && selectedEndpoint && selectedEndpoint.title) ? (
    <Dropdown
      clickInToClose
      justify="end"
      open={!endpointOnboarding && !!selectedEndpoint && !selectedEndpoint.title}
      sticky
    >
      <Button
        bem={{ white_text: true }}
        className={classes['TutorialEditor-Nav-Button']}
        title={(selectedEndpoint && selectedEndpoint.title) || ''}
        {...(readOnly &&
          selectedEndpoint &&
          selectedEndpoint.slug && {
            href: `${baseUrl}/reference/${selectedEndpoint.slug}`,
            rel: 'noopener noreferrer',
            target: '_blank',
          })}
      >
        {!!selectedEndpoint && !!selectedEndpoint.method && <Method type={selectedEndpoint.method.toLowerCase()} />}
        <span className={classes['TutorialEditor-Nav-Button-Text']}>
          {(selectedEndpoint && selectedEndpoint.title) || 'Choose Endpoint'}
        </span>
        {!readOnly && <i className="icon-chevron-down" />}
        {!!readOnly && !!selectedEndpoint && !!selectedEndpoint.slug && (
          <i className={['fa fa-arrow-circle-up', classes['TutorialEditor-Nav-Button-Link-Icon']].join(' ')} />
        )}
      </Button>

      {!readOnly && (
        <>
          {!endpointOnboarding && !!selectedEndpoint && !selectedEndpoint.title && (
            <div className={classes['TutorialEditor-Nav-Menu-ReferenceGuide']}>
              <span
                aria-label="finger pointing up"
                className={classes['TutorialEditor-Nav-Menu-ReferenceGuide-Avatar']}
                role="img"
              >
                ☝️
              </span>
              <p className={classes['TutorialEditor-Nav-Menu-ReferenceGuide-Description']}>
                Start from one of your API endpoints, or write a Recipe from scratch.
                <button
                  className={classes['TutorialEditor-Nav-Menu-ReferenceGuide-Description-Button']}
                  onClick={() => ApiActions.updateOnboarding(initRequest, { endpoints: true })}
                  type="button"
                >
                  Don’t show again
                </button>
              </p>
            </div>
          )}

          <Menu
            className={[
              classes['TutorialEditor-Nav-Menu-List'],
              !endpointOnboarding ? classes['TutorialEditor-Nav-Menu-List_onboarding'] : '',
              endpoints.length === 1 ? classes['TutorialEditor-Nav-Menu-List_overflow'] : '',
            ].join(' ')}
            theme="dark"
          >
            {!!selectedEndpoint && !!selectedEndpoint.title && (
              <React.Fragment>
                <MenuHeader>{selectedEndpoint.title}</MenuHeader>
                <MenuItem color="red" onClick={clearSnippetSelection}>
                  Clear Selection
                </MenuItem>
                <MenuDivider />
              </React.Fragment>
            )}
            {!!endpoints &&
              endpoints.length === 1 &&
              endpoints.map((endpoint, index) => (
                <React.Fragment key={`tutorial-endpoint-group-${index}`}>
                  <MenuHeader title={endpoint.title}>{endpoint.title}</MenuHeader>
                  {endpoint.children.map((child, idx) => (
                    <MenuItem
                      key={`tutorial-endpoint-opt-${idx}`}
                      onClick={() => handleSnippetSelection({ page: child, codeOptions })}
                      title={child.title}
                    >
                      <React.Fragment>
                        <Method
                          className={classes['TutorialEditor-Nav-Menu-Method']}
                          fixedWidth
                          type={child.api.method.toLowerCase()}
                        />
                        <span className={classes['TutorialEditor-Nav-Menu-Text']}>{child.title}</span>
                      </React.Fragment>
                    </MenuItem>
                  ))}
                </React.Fragment>
              ))}
            {!!endpoints && endpoints.length > 1 && (
              <React.Fragment>
                <MenuHeader>API Definitions</MenuHeader>
                {endpoints.map((endpoint, index) => (
                  <MenuItem key={`tutorial-endpoint-group-${index}`} openAt="left" title={endpoint.title}>
                    {endpoint.title}
                    <Menu theme="dark">
                      <MenuHeader>{endpoint.title}</MenuHeader>
                      {endpoint.children.map((child, idx) => (
                        <MenuItem
                          key={`tutorial-endpoint-opt-${idx}`}
                          onClick={() => handleSnippetSelection({ page: child, codeOptions })}
                          title={child.title}
                        >
                          <React.Fragment>
                            <Method
                              className={classes['TutorialEditor-Nav-Menu-Method']}
                              fixedWidth
                              type={child.api.method.toLowerCase()}
                            />
                            <span className={classes['TutorialEditor-Nav-Menu-Text']}>{child.title}</span>
                          </React.Fragment>
                        </MenuItem>
                      ))}
                    </Menu>
                  </MenuItem>
                ))}
              </React.Fragment>
            )}
          </Menu>
        </>
      )}
    </Dropdown>
  ) : null;
};

EndpointSelector.propTypes = {
  baseUrl: PropTypes.string,
  clearSnippetSelection: PropTypes.func,
  codeOptions: PropTypes.arrayOf(CodeOptionProp),
  endpointOnboarding: PropTypes.bool,
  endpoints: PropTypes.arrayOf(ApiEndpointProp),
  handleSnippetSelection: PropTypes.func,
  readOnly: PropTypes.bool,
  referenceEnabled: PropTypes.bool,
  requestDataRefresh: PropTypes.func,
  selectedEndpoint: EndpointProp,
};

const TabControls = ({ codeOption, languageOpts, selectedTab, isOnlyTab, handleTabUpdate, handleTabDelete }) => {
  const maxLength = 20;

  const handleNameInput = (event, key) => {
    const { value } = event.target;
    if (value.length >= maxLength) event.target.value = value.slice(0, maxLength);
    handleTabUpdate(event, key);
  };

  const { language, highlightedSyntax } = codeOption;

  return (
    <div className={classes['TutorialEditor-Controls']}>
      <label
        className={[
          classes['TutorialEditor-Controls-ControlWrapper'],
          classes['TutorialEditor-Controls-ControlWrapper_select'],
        ].join(' ')}
      >
        <span>Language</span>
        <select
          className={classes['TutorialEditor-Controls-ControlWrapper-Select']}
          onChange={e => handleTabUpdate(e, 'language')}
          value={JSON.stringify({ language, highlightedSyntax })}
        >
          {languageOpts.map(({ value, label }, idx) => (
            <option key={`tab-control-language-${idx}`} value={value}>
              {label}
            </option>
          ))}
        </select>
      </label>
      <label className={classes['TutorialEditor-Controls-ControlWrapper']}>
        <span>Name</span>
        <input
          className={classes['TutorialEditor-Controls-ControlWrapper-Input']}
          maxLength={maxLength}
          onChange={e => handleNameInput(e, 'name')}
          spellCheck="false"
          value={codeOption.name}
        />
      </label>
      <button
        className={classes['TutorialEditor-Controls-Delete']}
        data-tooltip={isOnlyTab ? 'Must have at least one tab' : ''}
        data-tooltip-position="left"
        disabled={isOnlyTab}
        onClick={() => handleTabDelete(selectedTab)}
        type="button"
      >
        Delete Tab
      </button>
    </div>
  );
};

TabControls.propTypes = {
  codeOption: CodeOptionProp,
  handleTabDelete: PropTypes.func,
  handleTabUpdate: PropTypes.func,
  isOnlyTab: PropTypes.bool,
  languageOpts: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  selectedTab: PropTypes.number,
};

export default function TutorialEditor({
  baseUrl,
  snippet,
  endpointOnboarding,
  endpoints,
  lineNumbers,
  referenceEnabled,
  requestDataRefresh,
  selectedTab,
  setSelectedTab,
  handleSnippetSelection,
  clearSnippetSelection,
  updateStep,
  updateSnippet,
  ...props
}) {
  const [copied, setCopied] = useState('Copy');
  const [highlight, setHighlight] = useState(false);
  const [editorInstance, setEditorInstance] = useState(null);
  const [rangeOpts, setRangeOpts] = useState([]);

  const { codeOptions, endpoint } = snippet;

  const SUPPORTED_LANGUAGES = getSupportedLanguages({
    plugins: [httpsnippetClientAPIPlugin],
  });

  const languageOpts = Object.keys(SUPPORTED_LANGUAGES).map(language => {
    const { highlight: highlightedSyntax } = SUPPORTED_LANGUAGES[language];
    return { value: JSON.stringify({ language, highlightedSyntax }), label: uppercase(language) };
  });

  // Get the defined dimensions based on the first selected range
  // to programmatically scroll. This is necessary because codemirror likes to put
  // a margin buffer in their component
  const getEditorDimensions = (editor, range, readOnly) => {
    const editorElement = readOnly ? editor : editor.getWrapperElement();
    const editorHeight = editorElement?.offsetHeight;
    const [start, end] = range;
    const lineHeight = editorElement.querySelector('.CodeMirror-line')?.offsetHeight || 16;

    const rangeHeight = (end.line - start.line) * lineHeight;
    let editorCenter = start.line * lineHeight - editorHeight / 2 - lineHeight / 2;

    // if there’s a range greater than one line
    if (rangeHeight > lineHeight) {
      editorCenter += rangeHeight / 2;
      // if the range is larger than the editor
      if (rangeHeight > editorHeight) editorCenter = start.line * lineHeight - lineHeight;
    }

    return { editorCenter };
  };

  const markEditorInstance = (editor, ranges) => {
    editor.refresh();

    // Clear existing style tags from our editor instance
    editor.getAllMarks().forEach(mark => mark.clear());

    // Mark new ranges with classname
    ranges.forEach(([anchor, head]) =>
      editor.getDoc().markText(anchor, head, { className: 'CodeEditor-Highlight', addToHistory: true }),
    );
    // Scroll to the first range so that code is in viewport
    const { editorCenter } = getEditorDimensions(editor, ranges[0]);
    editor.scrollTo(0, editorCenter);
  };

  // Ensure gutter has the correct size on load
  useEffect(() => {
    if (editorInstance) editorInstance.refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // MarkdownEditor user interaction marking
  // Called when lineNumbers is updated (via input or step/tab change)
  useEffect(() => {
    const newLines = lineNumbers[selectedTab];

    if (newLines) {
      const ranges = spreadLineNumbers(newLines);

      if (!editorInstance) setRangeOpts(ranges);
      if (editorInstance) setTimeout(() => markEditorInstance(editorInstance, ranges));
    }
  }, [lineNumbers[selectedTab], snippet.codeOptions[selectedTab].code, snippet.codeOptions.endpoint, editorInstance]); // eslint-disable-line react-hooks/exhaustive-deps

  // getCode() is called when two conditions are fulfilled:
  // 1. When a snippet is already selected and assigned to the tutorial and a new tab is created
  // 2. When we create a new step
  // getCode will retrieve a code string and place it within the tab's target index
  const getCode = (language, index) => {
    const { page } = snippet.endpoint;
    const pages = endpoints.reduce((arr, val) => arr.concat(val.children), []);
    const foundPage = pages.find(({ _id }) => _id === page);

    if (foundPage) props.getCode({ page: foundPage, language, index });
  };

  const handleTabCreate = codeOption => {
    lineNumbers.push('');
    updateStep({ lineNumbers });

    codeOptions.push(codeOption);
    updateSnippet({ codeOptions });

    setSelectedTab(codeOptions.length - 1);
    getCode(codeOption.language, snippet.codeOptions.length - 1);
  };

  const handleTabUpdate = (event, key) => {
    const { value } = event.target;

    if (key === 'language') {
      // eslint-disable-next-line try-catch-failsafe/json-parse -- @fixme
      const { language, highlightedSyntax } = JSON.parse(value);
      codeOptions[selectedTab].language = language;
      codeOptions[selectedTab].highlightedSyntax = highlightedSyntax;
      codeOptions[selectedTab].name = uppercase(language);
      getCode(language, selectedTab);
    } else {
      codeOptions[selectedTab][key] = value;
    }

    updateSnippet({ codeOptions });
  };

  const handleTabDelete = index => {
    lineNumbers.splice(index, 1);
    updateStep({ lineNumbers });

    codeOptions.splice(index, 1);
    updateSnippet({ codeOptions });

    setSelectedTab(0);
  };

  const handleCodeChange = ({ origin }, code, readOnly) => {
    if (['+input', 'paste', '+delete'].includes(origin) && !readOnly) {
      codeOptions[selectedTab].code = code;
      updateSnippet({ codeOptions });
    }
  };

  const checkCodeChange = process.env.NODE_ENV === 'test' ? handleCodeChange : debounce(handleCodeChange, 300);
  const readOnlyEditor = document.querySelector('.TutorialEditor-Nav + .CodeEditor');
  const codeMirrorOpts = readOnly => {
    if (readOnly) {
      if (typeof window !== 'undefined' && rangeOpts.length) {
        const { editorCenter } = getEditorDimensions(readOnlyEditor, rangeOpts[0], readOnly);
        readOnlyEditor.scroll({ top: editorCenter, left: 0, behavior: 'auto' });
      }
      return {
        className: `CodeEditor-Input CodeEditor-Input_readonly ${(highlight && 'CodeEditor-Input_highlight') || ''}`,
        dark: true,
        highlightMode: true,
        tokenizeVariables: true,
        ranges: rangeOpts,
        foldGutter: true,
      };
    }
    return { className: 'CodeEditor-Input', editable: true, dark: true, foldGutter: true };
  };

  const handleCopyToClipboard = () => {
    try {
      const targetCode = codeOptions[selectedTab]?.code || '';

      copy(targetCode);
      setCopied('Copied!');
    } catch (e) {
      setCopied('Unable to copy');
    } finally {
      // Reset the state after 2.5s
      setTimeout(() => setCopied('Copy'), 2500);
    }
  };

  return (
    <PermissionContext.Consumer>
      {readOnly => (
        <React.Fragment>
          <Flex
            align="center"
            className={['TutorialEditor-Nav', classes['TutorialEditor-Nav']].join(' ')}
            justify="between"
            tag="nav"
          >
            <SnippetLanguageMenu
              codeOptions={codeOptions}
              handleTabCreate={handleTabCreate}
              languageOpts={languageOpts}
              readOnly={readOnly}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
            />
            <Flex align="center">
              {!!readOnly && (
                <button
                  aria-label="Copy"
                  className={classes['TutorialEditor-Nav-Copy']}
                  data-tooltip={copied}
                  data-tooltip-position="bottom"
                  onBlur={() => setHighlight(false)}
                  onClick={handleCopyToClipboard}
                  onFocus={() => setHighlight(true)}
                  onMouseEnter={() => setHighlight(true)}
                  onMouseLeave={e => {
                    setHighlight(false);
                    e.target.blur();
                  }}
                  type="button"
                >
                  <Icon className={classes['TutorialEditor-Nav-Copy-Icon']} name="copy" size="lg" />
                </button>
              )}
              {!!(readOnly || endpoints.length > 0) && (
                <EndpointSelector
                  baseUrl={baseUrl}
                  clearSnippetSelection={clearSnippetSelection}
                  codeOptions={snippet.codeOptions}
                  endpointOnboarding={endpointOnboarding}
                  endpoints={endpoints}
                  handleSnippetSelection={handleSnippetSelection}
                  readOnly={readOnly}
                  referenceEnabled={referenceEnabled}
                  requestDataRefresh={requestDataRefresh}
                  selectedEndpoint={endpoint}
                />
              )}
            </Flex>
          </Flex>
          {syntaxHighlighter(
            codeOptions[selectedTab].code,
            codeOptions[selectedTab].highlightedSyntax,
            codeMirrorOpts(readOnly),
            {
              autoScroll: true,
              editorDidMount: setEditorInstance,
              onChange: (editor, data, code) => checkCodeChange(data, code, readOnly),
            },
          )}
          {!readOnly && (
            <TabControls
              codeOption={codeOptions[selectedTab]}
              handleTabDelete={handleTabDelete}
              handleTabUpdate={handleTabUpdate}
              isOnlyTab={codeOptions.length === 1}
              languageOpts={languageOpts}
              selectedTab={selectedTab}
            />
          )}
        </React.Fragment>
      )}
    </PermissionContext.Consumer>
  );
}

TutorialEditor.propTypes = {
  baseUrl: PropTypes.string,
  clearSnippetSelection: PropTypes.func,
  endpointOnboarding: PropTypes.bool,
  endpoints: PropTypes.arrayOf(ApiEndpointProp),
  getCode: PropTypes.func,
  handleSnippetSelection: PropTypes.func,
  lineNumbers: PropTypes.arrayOf(PropTypes.string),
  referenceEnabled: PropTypes.bool,
  requestDataRefresh: PropTypes.func,
  selectedTab: PropTypes.number,
  setSelectedTab: PropTypes.func,
  snippet: StepSnippetProp,
  updateSnippet: PropTypes.func,
  updateStep: PropTypes.func,
};
