import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import FocusLock from 'react-focus-lock';

import classy from '@core/utils/classy';

import classes from './style.module.scss';

class Modal extends React.Component {
  static propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    focusLockClassName: PropTypes.string,
    focusLockWhitelist: PropTypes.func,
    id: PropTypes.string,
    noDismiss: PropTypes.bool,
    onClose: PropTypes.func,
    onOpen: PropTypes.func,
    /** The modal's initial state.
     */
    open: PropTypes.bool,
    priority: PropTypes.bool,
    /**
     * Support modals rendered inside a shadow root. See
     * https://developer.mozilla.org/en-US/docs/Web/API/Node/getRootNode.
     */
    rootNode: PropTypes.element,
    scrollable: PropTypes.bool,
    sectionRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),
    /** DOM element to inject the modal in to.
     */
    target: PropTypes.string,
    verticalCenter: PropTypes.bool,
  };

  static defaultProps = {
    open: false,
    target: 'body',
  };

  /**
   * Private helper to query the target element if not already set.
   * @return {Element}
   */
  _getTargetElement() {
    // When targetElement is already set, do nothing more and return the same element.
    if (this.state?.targetElement) return this.state.targetElement;

    const rootNode = this.props.rootNode || document;

    return typeof window !== 'undefined' ? rootNode.querySelector(this.props.target) : null;
  }

  constructor(props) {
    super(props);

    // Initialize state
    this.state = {
      open: !!props.open,
    };

    this.el = React.createRef();
  }

  componentDidMount() {
    const targetElement = this._getTargetElement();

    if (targetElement && !this.props.open) {
      targetElement.style.visibility = 'hidden';
    }

    this.setState({
      targetElement,
    });
  }

  componentDidUpdate() {
    const { open, targetElement } = this.state;
    const { priority, noDismiss, verticalCenter } = this.props;

    if (targetElement) {
      targetElement.style.visibility = open ? 'visible' : 'hidden';

      if (noDismiss) {
        targetElement.classList.add('ModalWrapper_noDismiss');
      } else {
        targetElement.addEventListener('mousedown', this.handleClickOut);
        window.addEventListener('keydown', this.handleKeyDown);
        if (open) targetElement.classList.add(classes.Modal_open);
      }

      if (verticalCenter) {
        targetElement.classList.add('ModalWrapper_verticalCenter');
      }

      if (priority) {
        targetElement.classList.add('ModalWrapper_priority');
      }
    }
  }

  componentWillUnmount() {
    const { targetElement } = this.state;

    if (targetElement) {
      targetElement.removeEventListener('mousedown', this.handleClickOut);
      window.removeEventListener('keydown', this.handleKeyDown);
    }
  }

  toggle(toState, detail) {
    /* eslint-disable no-param-reassign */
    detail ||= typeof toState !== 'boolean' ? 'toggle' : toState ? 'open' : 'close';
    toState = typeof toState === 'boolean' ? toState : !this.state.open;
    /* eslint-enable no-param-reassign */

    // For timing reasons, sometimes the target element could not be found in the
    // constructor because the target element has not yet mounted. As a
    // fallback, we always try to find the element again here.
    this.setState({
      open: toState,
      targetElement: this._getTargetElement(),
    });

    setTimeout(() => {
      const { onClose, onOpen } = this.props;
      if (toState) {
        this.state.targetElement?.classList.add(classes.Modal_open);
        if (typeof onOpen === 'function') onOpen(detail);
        // remove scrolling while modal is open
        document.body.style.overflow = 'hidden';
      } else {
        this.state.targetElement?.classList.remove(classes.Modal_open);
        if (typeof onClose === 'function') onClose(detail);
        // restore scrolling
        document.body.style.overflow = '';
      }
    }, 0);
  }

  handleClickOut = ({ target }) => {
    if (target === this.state.targetElement && !this.props.noDismiss) this.toggle(false, 'clickOut');
  };

  handleKeyDown = ({ key }) => {
    if (key === 'Escape') this.toggle(false, 'escapeKey');
  };

  render() {
    const { open, targetElement } = this.state;
    const {
      children,
      className,
      focusLockClassName,
      focusLockWhitelist,
      noDismiss,
      onClose,
      onOpen,
      open: openProp,
      priority,
      rootNode,
      scrollable,
      sectionRef,
      size,
      target,
      verticalCenter,
      ...attrs
    } = this.props;
    const modalClasses = [
      'Modal',
      open && 'Modal_open',
      scrollable && 'Modal_scrollable',
      size && `Modal_${size}`,
      verticalCenter && 'Modal_verticalCenter',
      className,
    ].map(cn => (cn in classes ? classes[cn] : cn));

    return targetElement
      ? ReactDOM.createPortal(
          <section
            ref={sectionRef || this.el}
            aria-hidden={!open}
            aria-modal={true}
            className={classy(...modalClasses)}
            role="dialog"
            {...attrs}
          >
            <FocusLock
              autoFocus={false}
              className={classy(classes, 'Modal-FocusLock', focusLockClassName)}
              disabled={!open}
              whiteList={focusLockWhitelist}
            >
              {children}
            </FocusLock>
          </section>,
          targetElement,
        )
      : null;
  }
}

export { default as ModalHeader } from './Header';
export { default as ModalBody } from './Body';
export { default as ModalFooter } from './Footer';
export default Modal;
