import React from 'react';

import classes from './style.module.scss';

export interface ModalBodyProps extends React.HTMLAttributes<HTMLElement> {
  children?: React.ReactNode;
  className?: string;
}

function ModalBody({ children, className, ...attrs }: ModalBodyProps) {
  return (
    <div className={[classes['Modal-Body'], className || ''].join(' ')} {...attrs}>
      {children}
    </div>
  );
}

export default React.memo(ModalBody);
