import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useRef } from 'react';
import flattenChildren from 'react-keyed-flatten-children';
import { Link, NavLink } from 'react-router-dom';

import classy from '@core/utils/classy';

import Dropdown from '@ui/Dropdown';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';

import Menu from '..';
import '../style.scss';

const MenuItem = props => {
  const {
    TagName,
    active,
    alignIcon = 'left',
    children,
    className,
    color,
    description,
    disabled,
    focusable = true,
    href,
    icon,
    img,
    onClick,
    onKeyPress,
    openAt,
    target,
    title,
    value,
    style,
    ...attrs
  } = props;
  const rootRef = useRef(null);

  // Iterate through children to see if a submenu exists or not. Use the first
  // Menu instance that it finds, and use all other symbols for the label.
  const { content, menu: subMenu } = useMemo(
    () =>
      flattenChildren(children).reduce(
        (prev, curr) => {
          if (React.isValidElement(curr) && curr.type === Menu) {
            return prev.menu ? prev : { ...prev, menu: curr };
          }

          return { ...prev, content: [...prev.content, curr] };
        },
        { content: [], menu: null },
      ),
    [children],
  );

  const menuItemClasses = classy(
    'Menu-Item',
    alignIcon === 'left' ? 'Menu-Item-alignIcon_left' : 'Menu-Item-alignIcon_right',
    ['div', 'button', 'a', 'label', Link, NavLink].includes(TagName) && focusable && 'Menu-Item_link',
    active && 'Menu-Item_active',
    className,
    color && `Menu-Item_${color}`,
    description && 'Menu-Item_description',
    disabled && 'Menu-Item_link_disabled',
    href && target === '_blank' && alignIcon === 'left' && 'Menu-Item_external',
    subMenu && 'Menu-Item_arrow',
  );

  const handleKeyDown = useCallback(
    e => {
      if ((e.key !== ' ' && e.key !== 'Enter') || disabled) return;
      e.preventDefault();
      rootRef.current.click();
    },
    [disabled],
  );

  const renderedIcon = useMemo(() => (typeof icon === 'string' ? <i className={icon} /> : icon), [icon]);

  if (subMenu) {
    return (
      <Dropdown align={openAt} justify="start" trigger="hover">
        <div
          {...attrs}
          className={menuItemClasses}
          disabled={disabled}
          role="button"
          {...(focusable ? { tabIndex: disabled ? -1 : 0 } : {})}
        >
          {renderedIcon}
          {content}
        </div>
        <div className={`Menu-Item-SubMenu Menu-Item-SubMenu_${openAt}`}>{subMenu}</div>
      </Dropdown>
    );
  }

  const imgMenuItemClasses = classy('Menu-Item_img', img?.border && 'Menu-Item_img__border');

  return (
    <TagName
      {...(onClick && !disabled && { onClick, onKeyDown: handleKeyDown, role: 'button', tabIndex: 0 })}
      onKeyPress={onKeyPress}
      {...attrs}
      ref={rootRef}
      className={menuItemClasses}
      disabled={disabled}
      href={href}
      rel={target === '_blank' ? 'noreferrer' : null}
      style={style}
      target={target}
      title={title}
      {...(focusable ? { tabIndex: disabled ? -1 : 0 } : {})}
    >
      {!!img && (
        <img alt={img.alt || ''} className={imgMenuItemClasses} height={img.height} src={img.src} width={img.width} />
      )}
      {alignIcon === 'left' && renderedIcon}
      {description ? (
        <Flex align="stretch" gap="3px" layout="col">
          {!!children && (
            <Flex gap="xs" justify="between">
              {content}
            </Flex>
          )}
          {!!description && <div className="Menu-Item-description">{description}</div>}
        </Flex>
      ) : (
        content
      )}
      {alignIcon === 'right' && renderedIcon}
      {!!href && target === '_blank' && alignIcon === 'left' && (
        <Icon className="Menu-item-external-link-icon" name="arrow-up-right" />
      )}
    </TagName>
  );
};

MenuItem.propTypes = {
  active: PropTypes.bool,
  alignIcon: PropTypes.oneOf(['left', 'right']),
  children: PropTypes.any,
  className: PropTypes.string,
  color: PropTypes.oneOf(['blue', 'green', 'purple', 'red', 'yellow']),
  description: PropTypes.node,
  disabled: PropTypes.bool,
  focusable: PropTypes.bool,
  href: PropTypes.string,
  icon: PropTypes.node,
  id: PropTypes.string,
  img: PropTypes.shape({
    alt: PropTypes.string,
    border: PropTypes.bool,
    height: PropTypes.number,
    src: PropTypes.string.isRequired,
    width: PropTypes.number,
  }),
  name: PropTypes.string,
  onClick: PropTypes.func,
  onKeyPress: PropTypes.func,
  openAt: PropTypes.oneOf(['left', 'right']),
  role: PropTypes.string,
  style: PropTypes.object,
  tabIndex: PropTypes.string,
  TagName: PropTypes.elementType,
  target: PropTypes.oneOf(['_blank']),
  title: PropTypes.string,
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      pathname: PropTypes.string,
      state: PropTypes.object,
    }),
  ]),
  value: PropTypes.any,
};

MenuItem.defaultProps = {
  alignIcon: 'left',
  className: '',
  focusable: true,
  openAt: 'right',
  TagName: 'div',
};

export default React.memo(MenuItem);
