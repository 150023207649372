import rdmd from '@readme/markdown';
import PropTypes from 'prop-types';
import React from 'react';

import classy from '@core/utils/classy';

import Avatar from '@ui/Avatar';
import Button from '@ui/Button';

import { PermissionContext } from '../../PermissionContext';
import { TutorialProp } from '../Modal/proptypes/tutorials';

import classes from './style.module.scss';

const CardButtonBar = ({
  isAdmin,
  tutorial,
  makeHero,
  shiftOrder,
  numTutorials,
  openTutorial,
  publish,
  deleteTutorial,
  stagingUrl,
}) => {
  const { _id, published, title, slug } = tutorial;
  if (!isAdmin) return false;

  return stagingUrl ? (
    <button
      className={classes['TutorialCard-Toolbar-Button']}
      onClick={() => {
        window.open(stagingUrl, '_blank');
      }}
    >
      Edit Recipes on Staging
    </button>
  ) : (
    <React.Fragment>
      <button
        aria-label="Show Previous Card"
        className={classes['TutorialCard-Toolbar-Arrow']}
        disabled={tutorial.order === 0}
        onClick={() => shiftOrder({ _id, direction: -1, slug })}
      >
        <i className="fa fa-arrow-left" />
      </button>
      <button
        aria-label="Show Next Card"
        className={classes['TutorialCard-Toolbar-Arrow']}
        disabled={tutorial.order === numTutorials - 1}
        onClick={() => shiftOrder({ _id, direction: 1, slug })}
      >
        <i className="fa fa-arrow-right" />
      </button>
      <button className={classes['TutorialCard-Toolbar-Button']} onClick={() => openTutorial('Edit', tutorial)}>
        Edit
      </button>
      <button
        className={classes['TutorialCard-Toolbar-Button']}
        disabled={tutorial.hero}
        onClick={() => publish({ ...tutorial, published: !published })}
        title={tutorial.hero ? 'You can’t unpublish a featured Recipe' : ''}
      >
        {tutorial.published ? 'Unpublish' : 'Publish'}
      </button>
      {!tutorial.hero && (
        <button
          className={classes['TutorialCard-Toolbar-Button']}
          disabled={tutorial.hero}
          onClick={() => makeHero({ _id, published, slug })}
        >
          Feature
        </button>
      )}
      <button
        className={[classes['TutorialCard-Toolbar-Button'], classes['TutorialCard-Toolbar-Button_red']].join(' ')}
        disabled={!!tutorial.hero && numTutorials > 1}
        onClick={() => deleteTutorial({ _id, title, slug })}
        title={tutorial.hero ? (numTutorials > 1 ? '' : 'You can’t delete a featured Recipe') : ''}
      >
        Delete
      </button>
    </React.Fragment>
  );
};

CardButtonBar.propTypes = {
  deleteTutorial: PropTypes.func,
  isAdmin: PropTypes.bool,
  makeHero: PropTypes.func,
  numTutorials: PropTypes.number,
  openTutorial: PropTypes.func,
  publish: PropTypes.func,
  shiftOrder: PropTypes.func,
  stagingUrl: PropTypes.string,
  tutorial: TutorialProp,
};

const renderStatus = (isAdmin, tutorial) => {
  const { emoji, hero, published } = tutorial;
  if (!isAdmin) return false;
  if (hero || !published) {
    return (
      <div
        className={[
          classes['TutorialCard-Status'],
          hero ? classes['TutorialCard-Status_featured'] : '',
          !emoji ? classes['TutorialCard-Status_noemoji'] : '',
        ].join(' ')}
      >
        <i className={[classes['TutorialCard-Status-Icon'], hero ? 'fa fa-star' : 'icon icon-eye-off'].join(' ')} />
        {hero ? 'Featured' : 'Not Published'}
      </div>
    );
  }
  return false;
};

export default function TutorialCard({
  tutorialReview,
  className,
  numTutorials,
  tutorial,
  makeHero,
  openTutorial,
  publish,
  shiftOrder,
  deleteTutorial,
  stagingUrl,
}) {
  const tutorialCardClasses = classy(
    className,
    classes.TutorialCard,
    !tutorial.published && !tutorialReview && classes.TutorialCard_unpublished,
    'rm-RecipeCard',
  );
  return (
    <section className={tutorialCardClasses}>
      <div className={classes['TutorialCard-Top']}>
        <PermissionContext.Consumer>
          {isAdmin => renderStatus(isAdmin && !tutorialReview, tutorial)}
        </PermissionContext.Consumer>
        {!!tutorial.emoji && (
          <Avatar
            className={classy(classes['TutorialCard-Avatar'], 'rm-RecipeCard-avatar')}
            hexColor={tutorial.backgroundColor}
          >
            {tutorial.emoji}
          </Avatar>
        )}
        <div className={classes['TutorialCard-Title']}>{tutorial.title}</div>
        <div className={`rm-Markdown markdown-body ${classes['TutorialCard-Description']}`}>
          {rdmd(tutorial.description)}{' '}
        </div>
        <div className={classes['TutorialCard-Steps']}>{tutorial.stepCount} Steps</div>
        <Button
          bem={{ blue: true }}
          className={classy(classes['TutorialCard-Button'], 'rm-RecipeCard-button')}
          onClick={() => openTutorial('View', tutorial)}
        >
          Open Recipe
        </Button>
      </div>
      <PermissionContext.Consumer>
        {isAdmin => (
          <footer className={classes['TutorialCard-Toolbar']}>
            <div className={classes['TutorialCard-Toolbar-Wrapper']}>
              <CardButtonBar
                deleteTutorial={deleteTutorial}
                isAdmin={!!isAdmin && !tutorialReview}
                makeHero={makeHero}
                numTutorials={numTutorials}
                openTutorial={openTutorial}
                publish={publish}
                shiftOrder={shiftOrder}
                stagingUrl={stagingUrl}
                tutorial={tutorial}
              />
            </div>
          </footer>
        )}
      </PermissionContext.Consumer>
    </section>
  );
}

TutorialCard.propTypes = {
  deleteTutorial: PropTypes.func,
  makeHero: PropTypes.func,
  numTutorials: PropTypes.number,
  openTutorial: PropTypes.func,
  publish: PropTypes.func,
  shiftOrder: PropTypes.func,
  stagingUrl: PropTypes.string,
  tutorial: TutorialProp,
  tutorialReview: PropTypes.bool,
};

TutorialCard.defaultProps = {
  tutorialReview: false,
};
