import React, { useCallback, useEffect, useState } from 'react';
import ChromePicker from 'react-color';

import useClassy from '@core/hooks/useClassy';
import useDebounced from '@core/hooks/useDebounced';

import Dropdown from '@ui/Dropdown';

import Button from '../Button';

import classes from './style.module.scss';

const hexRegex = /^#([0-9a-f]{3}|[0-9a-f]{6})$/i;
type BttnSizeType = 'lg' | 'md' | 'sm' | 'xs' | undefined;

interface ColorEvent {
  hex: string;
}

interface ColorPickerProps {
  circular?: boolean;
  className?: string;
  color?: string | null;
  debounceOnChange?: number;
  id?: string | null;
  onChange: (color: string) => void;
  placeholder?: string | null;
  size?: 'lg' | 'md' | 'sm';
  wrapperClassName?: string;
}

function ColorPicker({
  color,
  circular,
  className = '',
  debounceOnChange = 0,
  placeholder,
  onChange,
  size = 'md',
  wrapperClassName = '',
}: ColorPickerProps) {
  const bem = useClassy(classes, 'ColorPicker');
  const [currentColor, setCurrentColor] = useState(color || placeholder || '#018EF5');
  const onChangeDebounced = useDebounced((c: string) => onChange(c), debounceOnChange);
  const isPlaceholderShown = placeholder && (currentColor === placeholder || currentColor === '');

  useEffect(() => {
    if (color) {
      const isValidHex = hexRegex.test(color);
      setCurrentColor(isValidHex ? color : '');
    } else if (color === '') {
      setCurrentColor('');
    }
  }, [color]);

  const handleDrag = useCallback(
    (c: ColorEvent) => {
      setCurrentColor(c.hex);
      if (typeof onChange === 'function' && !debounceOnChange) onChange(c.hex);
    },
    [debounceOnChange, onChange],
  );

  const handleChange = useCallback(
    (c: ColorEvent) => {
      if (typeof onChange === 'function' && debounceOnChange) {
        onChangeDebounced(c.hex);
      }
    },
    [debounceOnChange, onChangeDebounced, onChange],
  );

  return (
    <Dropdown appendTo={() => document.body} className={bem('&', className, `_${size}`)} justify="start" sticky>
      <Button
        className={bem('&-button')}
        data-testid="ColorPicker-Button"
        kind="secondary"
        outline={true}
        size={size as BttnSizeType}
      >
        <div
          className={bem('&-swatch', circular && '&-swatch-circle', wrapperClassName)}
          style={{ backgroundColor: `${currentColor || placeholder}` }}
        />
        <div className={bem('&-value', isPlaceholderShown && '&-value-placeholder')} data-testid="ColorPicker-Value">
          {currentColor || placeholder}
        </div>
      </Button>

      <ChromePicker
        className={bem('&-input')}
        color={currentColor}
        disableAlpha={true}
        onChange={handleDrag}
        onChangeComplete={handleChange}
      />
    </Dropdown>
  );
}

export default React.memo(ColorPicker);
