import type { TippyProps } from '@tippyjs/react';
import type { ForwardedRef } from 'react';
import type { Instance, Placement } from 'tippy.js';

import Tippy from '@tippyjs/react';
import React, { useState, useEffect, useRef, useCallback, memo, forwardRef, useMemo, useImperativeHandle } from 'react';

import useDebounced from '@core/hooks/useDebounced';
import classy from '@core/utils/classy';

import LazyTippy from '@ui/LazyTippy';

import FocusableController from './FocusableController';
import './style.scss';

const cap = (s: string) => s.replace(/./, c => c.toUpperCase());

/**
 * Forwarded ref can be used to expose toggle function and open/close the dropdown from outside the component.
 * This type can be used with `useRef` hook
 */
export interface DropdownRef extends HTMLDivElement {
  toggle: (toggleOpen?: boolean) => void;
  triggerElement: HTMLElement | null;
}

export interface DropdownProps {
  /** Where to align the Tippy element dropdown content (in conjunction with `justify` prop) */
  align?: 'auto' | 'bottom' | 'left' | 'right' | 'top';
  /** Where to append the Tippy element dropdown content */
  appendTo?: Element | 'parent' | ((ref: Element) => Element) | undefined;
  children?: React.ReactNode | null;
  className?: string;
  /** Should clicking in the content close the dropdown (defaults to false, meaning outside clicks will close dropdown) */
  clickInToClose?: boolean;
  /** Should the dropdown content be full width */
  fullWidth?: boolean;
  id?: string;
  /** Where to justify the Tippy element dropdown content (in conjunction with `align` prop) */
  justify?: 'center' | 'end' | 'start';
  /**
   * Whether to lazy mount the content using `LazyTippy`
   * This means the content will only be rendered the tooltip is mounted (visible).
   * It should only be used in performance-sensitive cases where the content is expensive to render
   */
  lazyMountContent?: boolean;
  /**
   * Offset of the Tippy content
   * Displaces the tippy from its reference element in pixels (skidding and distance).
   */
  offset?: TippyProps['offset'];
  onBlur?: (value: boolean | unknown) => void;
  onToggle?: (value: boolean, e: unknown) => void;
  /** Controlled prop for whether the dropdown is open */
  open?: boolean;
  /** Whether the dropdown should be sticky (used in conjunction with 'click' trigger) */
  sticky?: boolean;
  style?: React.CSSProperties;
  /** Additional options to pass to the Tippy component */
  tippyOptions?: TippyProps;
  /** The trigger type for the dropdown */
  trigger?: '' | 'click' | 'contextMenu' | 'focus' | 'hover' | 'manual';
  zIndex?: number;
}

const Dropdown = (
  {
    align = 'bottom',
    appendTo = 'parent',
    clickInToClose = false,
    fullWidth = false,
    justify = 'end',
    id,
    lazyMountContent = false,
    offset = [0, 4],
    open = false,
    sticky = false,
    trigger = 'click',
    className,
    onBlur,
    onToggle,
    tippyOptions,
    children,
    style = {},
    zIndex,
  }: DropdownProps,
  forwardedRef: ForwardedRef<DropdownRef>,
) => {
  const [isOpen, setIsOpen] = useState(open);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const focusable = useRef(new FocusableController());
  const clickType = `on${cap(trigger)}`;
  const isClickTriggered = useMemo(() => ['click', 'contextmenu', 'focus'].includes(trigger.toLowerCase()), [trigger]);

  const toggle = useCallback(
    e => {
      if (trigger === 'contextMenu' && e?.preventDefault) e.preventDefault();
      const value = [true, false].includes(e) ? e : !isOpen;
      setIsOpen(value);
      if (!value && trigger !== 'focus') focusable.current.returnFocus();
      if (typeof onToggle === 'function') {
        if (e && 'preventDefault' in e) e.preventDefault();
        onToggle(value, e);
      }
    },
    [isOpen, onToggle, trigger],
  );

  const handleClickOutside = useCallback(
    e => {
      const dropdown = 'current' in dropdownRef && dropdownRef.current;
      if (
        isOpen &&
        dropdown &&
        contentRef.current &&
        !(
          dropdown.contains(e.target) ||
          dropdown.nextSibling?.contains(e.target) ||
          contentRef.current.contains(e.target)
        )
      ) {
        toggle(e);
      }
    },
    [isOpen, toggle],
  );

  useImperativeHandle(
    forwardedRef,
    () => ({
      ...(forwardedRef as React.MutableRefObject<HTMLDivElement>).current,
      triggerElement: dropdownRef.current,
      toggle,
    }),
    [toggle, forwardedRef],
  );

  useEffect(() => {
    if (isClickTriggered && sticky && !clickInToClose) {
      document.addEventListener('mouseup', handleClickOutside);
    }
    return () => {
      if (isClickTriggered && sticky && !clickInToClose) {
        document.removeEventListener('mouseup', handleClickOutside);
      }
    };
  }, [isClickTriggered, sticky, clickInToClose, isOpen, toggle, handleClickOutside]);

  const handleClickInside = useCallback(
    e => {
      if (!clickInToClose) {
        return;
      }

      const disabledAttr = e.target.getAttribute('disabled');
      if (['true', ''].includes(disabledAttr)) {
        return;
      }

      toggle(e);
    },
    [clickInToClose, toggle],
  );

  const handleBlur = useCallback(
    e => {
      // Don't close the dropdown if the next focus is within the content
      const isNextFocusWithinContent =
        contentRef.current?.contains(e.relatedTarget) || e.relatedTarget?.contains(contentRef.current);
      // Don't close the dropdown if the next focus is on the trigger
      const isNextFocusOnTrigger = dropdownRef.current?.contains(e.relatedTarget);

      if (!isNextFocusWithinContent && !isNextFocusOnTrigger) {
        toggle(false);
      }
    },
    [toggle],
  );

  const handleTriggerKeydown = useCallback(
    e => {
      switch (e.key) {
        case 'ArrowUp':
        case 'ArrowDown':
          if (!isOpen) {
            toggle(e);
          } else {
            focusable.current.focusAt(0);
          }
          e.preventDefault();
          break;
        case 'Tab':
          if (!e.shiftKey && isOpen) {
            e.preventDefault();
            focusable.current.focusAfter();
          }
          break;
        default:
          break;
      }
    },
    [isOpen, toggle],
  );

  const handleContentKeyDown = useCallback(
    e => {
      if (e.key === 'Escape') {
        toggle(e);
      }

      if (e.key === 'Enter' && clickInToClose) {
        toggle(e);
      }
    },
    [clickInToClose, toggle],
  );

  const debouncedMouseLeave = useDebounced(() => !sticky && toggle(false), 75);

  const hoverEvents = {
    ...(trigger === 'hover' && {
      onMouseEnter: (e: React.MouseEvent<HTMLElement>) => {
        debouncedMouseLeave.cancel();
        if (!isOpen) toggle(e);
      },
      onClick: () => clickInToClose && toggle,
    }),
    onMouseLeave: debouncedMouseLeave,
  };

  const [buttonChild, ...content] = Array.isArray(children) ? children : [undefined, children];

  const triggerElement = useMemo(
    () => (React.isValidElement(buttonChild) ? buttonChild : <button>{buttonChild || 'Toggle'}</button>),
    [buttonChild],
  );

  useEffect(() => {
    if (isOpen && contentRef.current) {
      // Recalculate focusable elements on content changes
      focusable.current.queryFocusableElements(contentRef.current);
    }
  }, [isOpen, children]);

  const dropdownClasses: string = classy(
    className,
    'Dropdown',
    `Dropdown_${isOpen ? 'opened' : 'closed'}`,
    fullWidth && 'Dropdown_fullWidth',
  );

  const clickEvents = isClickTriggered
    ? {
        [clickType]: (e: React.FocusEvent<HTMLElement> | React.MouseEvent<HTMLElement>) => {
          triggerElement?.props?.[clickType]?.(e);
          toggle(e);
        },
      }
    : {};

  const focusEvents = {
    onFocus: (e: React.FocusEvent<HTMLElement>) => {
      triggerElement?.props?.onFocus?.(e);
      if (trigger === 'focus') toggle(e);
    },
    onBlur: (e: React.FocusEvent<HTMLElement>) => {
      triggerElement?.props?.onBlur?.(e);
      if (trigger === 'focus') handleBlur(e);
    },
  };

  const triggerElWithProps = React.cloneElement(triggerElement, {
    ref: dropdownRef,
    className: classy(triggerElement?.props?.className, 'Dropdown-toggle'),
    ...clickEvents,
    ...focusEvents,
    onKeyDown: (e: React.KeyboardEvent<HTMLElement>) => {
      triggerElement?.props?.onKeyDown?.(e);
      handleTriggerKeydown(e);
    },
    'aria-haspopup': 'dialog',
  });

  const justifyProp = justify ? (justify === 'center' ? '' : `-${justify}`) : '';

  const TippyComponent = lazyMountContent ? LazyTippy : Tippy;

  return (
    <div className={dropdownClasses} id={id} style={style} {...hoverEvents}>
      <TippyComponent
        appendTo={appendTo}
        arrow={false}
        content={
          <div
            ref={contentRef}
            className={`Dropdown-content Dropdown-content_${isOpen ? 'opened' : 'closed'}`}
            onBlur={handleBlur}
            onClick={handleClickInside}
            onKeyDown={handleContentKeyDown}
            role="dialog"
          >
            {content}
          </div>
        }
        interactive
        maxWidth="none"
        offset={offset}
        onHide={(e: Instance) => {
          focusable.current.exit();
          onBlur?.(e);
        }}
        onMount={() => contentRef.current && focusable.current.enter(contentRef.current)}
        placement={`${align}${justifyProp}` as Placement}
        visible={isOpen}
        zIndex={zIndex || 99999}
        {...tippyOptions}
      >
        {triggerElWithProps}
      </TippyComponent>
    </div>
  );
};

export default memo(forwardRef(Dropdown));
