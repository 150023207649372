import type { ReactNode } from 'react';

import React from 'react';

import useClassy from '@core/hooks/useClassy';

import './style.scss';

export type TitleLevel = 1 | 2 | 3 | 4 | 5 | 6;

interface TitleProps {
  /** semantic level
   */
  as?: TitleLevel;
  children?: ReactNode;
  /** class names (using `classy`)
   */
  className?: string;
  /** display level
   */
  level?: TitleLevel;
  onClick?: () => void;
  /** title text, or via `children`
   */
  text?: string;

  /** Optional HTML title attr prop to include, useful for overflow hidden tags */
  title?: string;
}

const Title = ({ as, level = 2, text = 'Title', className = '', children, onClick, ...props }: TitleProps) => {
  const Tag = `h${as || level}` as keyof JSX.IntrinsicElements;
  const bem = useClassy({}, 'Title');
  return (
    <Tag {...props} className={bem('&', `&${level}`, className)} onClick={onClick}>
      {children || text}
    </Tag>
  );
};

export default Title;
