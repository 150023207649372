import React from 'react';

import Button from '@ui/Button';
import Flex from '@ui/Flex';

import classes from './style.module.scss';

export interface ModalHeaderProps extends React.HTMLAttributes<HTMLElement> {
  children?: React.ReactNode;
  className?: string;
  gap?: number | string | 'lg' | 'md' | 'sm' | 'xl' | 'xs' | 'xxl';
  position?: 'between' | 'center';
  toggleClose?: () => void;
}

export default function ModalHeader({
  children,
  className,
  toggleClose,
  gap,
  position = 'between',
  ...attrs
}: ModalHeaderProps) {
  return (
    <header className={[classes['Modal-Header'], className || ''].join(' ')} {...attrs}>
      <Flex align="center" gap={gap} justify={position}>
        {children}
        {!!toggleClose && (
          <Button ghost kind="minimum" onClick={toggleClose} size="sm">
            <i className="icon-x" />
          </Button>
        )}
      </Flex>
    </header>
  );
}
