/**
 * List of CSS queries to select all focusable elements within a container.
 */
const focusableQueries = [
  'button:not([tabindex="-1"]):not([disabled])',
  '[href]:not([tabindex="-1"])',
  'input:not([tabindex="-1"]):not([disabled])',
  'select:not([tabindex="-1"]):not([disabled])',
  'textarea:not([tabindex="-1"]):not([disabled])',
  '[tabindex]:not([tabindex="-1"])',
];

function isInputElement(element: HTMLElement) {
  const tagName = element.tagName.toLowerCase();
  return ['input', 'select', 'textarea'].includes(tagName);
}

/**
 * Focuses the next focusable element after the given element.
 */
function focusAfterElement(element: HTMLElement | null) {
  const activeElement = document.activeElement as HTMLElement;

  const focusableElements = Array.prototype.filter.call(
    document.querySelectorAll(focusableQueries.join(',')),
    el => el === activeElement || !element?.contains(el),
  );

  const idx = focusableElements.indexOf(activeElement);
  if (idx > -1) {
    const nextElement = focusableElements[idx + 1] || focusableElements[0];
    nextElement.focus();
  }
}

/**
 * Controller manages focus navigation within a parent container.
 *
 * Implements keyboard interaction based on the WAI-ARIA combobox pattern:
 * @link https://www.w3.org/WAI/ARIA/apg/patterns/combobox/
 *
 */
class FocusableController {
  elements: NodeListOf<HTMLElement> | null = null;

  elementsIndexMap = new WeakMap<Node, number>();

  lastFocused: HTMLElement | null = null;

  parent: HTMLElement | null = null;

  get index() {
    return this.elementsIndexMap.get(document.activeElement as NonNullable<HTMLElement>);
  }

  #handleFirstFocus = (e: FocusEvent) => {
    const unfocusedElement = e.relatedTarget as HTMLElement;
    if (!this.parent?.contains(unfocusedElement)) {
      this.lastFocused = unfocusedElement;
    }
  };

  #handleKeyDown = (e: KeyboardEvent) => {
    switch (e.key) {
      case 'ArrowUp': {
        this.prev();
        e.preventDefault();
        break;
      }
      case 'ArrowDown':
        this.next();
        e.preventDefault();
        break;
      case 'Tab':
        this.focusAfter();
        e.preventDefault();
        break;
      default:
        break;
    }
  };

  focusAt(index: number) {
    if (!Number.isInteger(index) || !this.elements?.length) return;

    const totalFocusable = this.elements.length;
    const nextIndex = (index + totalFocusable) % totalFocusable;
    this.elements[nextIndex].focus();
  }

  focusAfter() {
    focusAfterElement(this.parent);
    this.exit();
  }

  next() {
    if (this.index === undefined) return;
    this.focusAt(this.index + 1);
  }

  prev() {
    if (this.index === undefined) return;
    this.focusAt(this.index - 1);
  }

  returnFocus() {
    this.lastFocused?.focus({ preventScroll: true });
  }

  queryFocusableElements(element: HTMLElement) {
    if (!element) return;

    this.elements = element.querySelectorAll(focusableQueries.join(',')) || [];
    this.elements.forEach((el, index) => this.elementsIndexMap.set(el, index));
  }

  enter(element: HTMLElement) {
    if (!element) return;

    this.parent = element;
    this.queryFocusableElements(this.parent);
    this.parent.addEventListener('focusin', this.#handleFirstFocus, { once: true });
    this.parent.addEventListener('keydown', this.#handleKeyDown);

    // Move focus to first element only when not coming from an input field.
    if (!isInputElement(document.activeElement as HTMLElement)) {
      this.focusAt(0);
    }
  }

  exit() {
    this.elements = null;
    this.elementsIndexMap = new WeakMap();
    this.parent?.removeEventListener('keydown', this.#handleKeyDown);
    this.parent = null;
  }
}

export default FocusableController;
