import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import Method from '@ui/API/Method';
import Menu from '@ui/Menu';
import MenuItem from '@ui/Menu/Item';

import { ApiEndpointProp } from '../../proptypes/apiEndpoints';

import classes from './style.module.scss';

const TutorialReferencePicker = ({ endpoints, referenceDisplay, setReferenceSelection }) => {
  const [subMenu, toggleSubMenu] = useState(false);
  const [endpoint, setEndpoint] = useState({});
  const [selection, setSelection] = useState({});

  const paintReferenceSelections = () => {
    const selectionMap = endpoints.reduce((obj, ep) => {
      const active = ep.children.reduce((acc, { _id }) => {
        if (referenceDisplay.includes(_id)) acc[_id] = true;
        return acc;
      }, {});
      obj[ep.categoryId] = { ...active };
      return obj;
    }, {});

    setSelection(selectionMap);
  };

  useEffect(() => {
    paintReferenceSelections();
  }, [referenceDisplay.length]); // eslint-disable-line react-hooks/exhaustive-deps

  if (subMenu) {
    return (
      <Menu className={classes.TutorialReferencePicker}>
        <MenuItem
          onClick={() => {
            setEndpoint({});
            toggleSubMenu(false);
          }}
        >
          <div className={classes['TutorialReferencePicker-Header']}>
            <span className={classes['TutorialReferencePicker-Header-BackArrow']}>◀</span>
            <span className={classes['TutorialReferencePicker-Item-Name']}>{endpoint.title}</span>
          </div>
        </MenuItem>
        {!!endpoint.children &&
          endpoint.children.map((child, index) => (
            <MenuItem key={`TutorialReferencePicker-Child-${index}`} onClick={() => setReferenceSelection(child._id)}>
              <div className={classes['TutorialReferencePicker-Item']}>
                <i
                  className={[
                    'fa fa-check',
                    classes['TutorialReferencePicker-Check'],
                    selection[endpoint.categoryId][child._id] ? classes['TutorialReferencePicker-Check_selected'] : '',
                  ].join(' ')}
                />
                <span className={classes['TutorialReferencePicker-Item-Name']} title={child.title}>
                  <Method
                    className={classes['TutorialReferencePicker-Item-Name-Method']}
                    fixedWidth
                    type={child.api.method.toLowerCase()}
                  />
                  <span className={classes['TutorialReferencePicker-Item-Name-Text']}>{child.title}</span>
                </span>
              </div>
            </MenuItem>
          ))}
      </Menu>
    );
  }

  return (
    <Menu className={classes.TutorialReferencePicker}>
      {!!endpoints &&
        endpoints.map((ep, index) => (
          <MenuItem
            key={`TutorialReferencePicker-Parent-${index}`}
            className="Menu-Item_arrow"
            onClick={() => {
              setEndpoint(ep);
              toggleSubMenu(true);
            }}
          >
            <div className={classes['TutorialReferencePicker-Item']}>
              <i
                className={[
                  'fa fa-check',
                  classes['TutorialReferencePicker-Check'],
                  selection[ep.categoryId] && Object.keys(selection[ep.categoryId]).length
                    ? classes['TutorialReferencePicker-Check_selected']
                    : '',
                ].join(' ')}
              />
              <span className={classes['TutorialReferencePicker-Item-Name']}>{ep.title}</span>
            </div>
          </MenuItem>
        ))}
    </Menu>
  );
};

TutorialReferencePicker.propTypes = {
  endpoints: PropTypes.arrayOf(ApiEndpointProp),
  referenceDisplay: PropTypes.arrayOf(PropTypes.string),
  setReferenceSelection: PropTypes.func,
};

export default TutorialReferencePicker;
