import PropTypes from 'prop-types';
import React from 'react';

import Button from '@ui/Button';

import { PermissionContext } from '../../PermissionContext';

import classes from './style.module.scss';

export default function TutorialStepNav(props) {
  const { children, createStep } = props;

  return (
    <PermissionContext.Consumer>
      {readOnly => (
        <div className={classes.TutorialStepNav}>
          {children}
          {!readOnly && (
            <Button className={classes['TutorialStepNav-Button']} onClick={createStep}>
              <i className="fa fa-plus" />
              <span>Add Step</span>
            </Button>
          )}
        </div>
      )}
    </PermissionContext.Consumer>
  );
}
TutorialStepNav.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  createStep: PropTypes.func,
};

TutorialStepNav.defaultProps = {
  children: '',
};
