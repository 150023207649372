import PropTypes from 'prop-types';
import React from 'react';

import type { FlexProps } from '@ui/Flex';
import Flex from '@ui/Flex';

import classes from './style.module.scss';

export interface ModalFooterProps extends Pick<FlexProps, 'align' | 'gap' | 'justify'> {
  children?: React.ReactNode;
  className?: string;
}

export default function ModalFooter({ align, children, className, justify = 'end', ...attrs }: ModalFooterProps) {
  return (
    <Flex
      align={align}
      className={[classes['Modal-Footer'], className || ''].join(' ')}
      gap="sm"
      justify={justify}
      tag="footer"
      {...attrs}
    >
      {children}
    </Flex>
  );
}

ModalFooter.propTypes = {
  align: PropTypes.oneOf(['center', 'start', 'end', 'baseline', 'stretch']),
  children: PropTypes.any,
  className: PropTypes.node,
  justify: PropTypes.oneOf(['center', 'start', 'end', 'between', 'around']),
};
