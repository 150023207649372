/* istanbul ignore file */
import PropTypes from 'prop-types';
import React from 'react';

import Title from '@ui/Title';

import TutorialCard from '../Card';
import { TutorialProp } from '../Modal/proptypes/tutorials';

import classes from './style.module.scss';

const TutorialObstacle = ({ body, children, className, closeTutorialModal, heading, tutorial }) => (
  <div className={[classes.TutorialObstacle, className || ''].join(' ')}>
    {!!closeTutorialModal && (
      <button className={classes['TutorialObstacle-Close']} onClick={closeTutorialModal} type="button">
        <i className="icon icon-x" />
      </button>
    )}
    <div className={classes['TutorialObstacle-Top']}>
      {!!heading && (
        <Title className={classes['TutorialObstacle-Title']} level={1}>
          {heading}
        </Title>
      )}
      <p className={classes['TutorialObstacle-Description']}>{body}</p>
      <div className={classes['TutorialObstacle-Buttons']}>{children}</div>
    </div>
    <div className={classes['TutorialObstacle-Img']}>
      {/* prettier-ignore */}
      <svg className={classes['TutorialObstacle-Img-Stars']} viewBox="0 0 575 183" xmlns="http://www.w3.org/2000/svg">
        <g className={classes['TutorialObstacle-Img-Stars-Star']}>
          <line stroke="#939eae" strokeLinecap="round" strokeLinejoin="round" x1="471.5" x2="471.5" y1="118.5" y2="122.5" />
          <line stroke="#939eae" strokeLinecap="round" strokeLinejoin="round" x1="473.5" x2="469.5" y1="120.5" y2="120.5" />
        </g>
        <g className={classes['TutorialObstacle-Img-Stars-Star']}>
          <line stroke="#f0f0f0" strokeLinecap="round" strokeLinejoin="round" x1="572.5" x2="572.5" y1="54.5" y2="58.5" />
          <line stroke="#f0f0f0" strokeLinecap="round" strokeLinejoin="round" x1="574.5" x2="570.5" y1="56.5" y2="56.5" />
        </g>
        <g className={classes['TutorialObstacle-Img-Stars-Star']}>
          <line stroke="#4F5a66" strokeLinecap="round" strokeLinejoin="round" x1="490.5" x2="490.5" y1="42.5" y2="46.5" />
          <line stroke="#4F5a66" strokeLinecap="round" strokeLinejoin="round" x1="492.5" x2="488.5" y1="44.5" y2="44.5" />
        </g>
        <g className={classes['TutorialObstacle-Img-Stars-Star']}>
          <line stroke="#939eae" strokeLinecap="round" strokeLinejoin="round" x1="498.5" x2="498.5" y1="95.5" y2="99.5" />
          <line stroke="#939eae" strokeLinecap="round" strokeLinejoin="round" x1="500.5" x2="496.5" y1="97.5" y2="97.5" />
        </g>
        <g className={classes['TutorialObstacle-Img-Stars-Star']}>
          <line stroke="#939eae" strokeLinecap="round" strokeLinejoin="round" x1="468.5" x2="468.5" y1="175.5" y2="179.5" />
          <line stroke="#939eae" strokeLinecap="round" strokeLinejoin="round" x1="470.5" x2="466.5" y1="177.5" y2="177.5" />
        </g>
        <g className={classes['TutorialObstacle-Img-Stars-Star']}>
          <line stroke="#939eae" strokeLinecap="round" strokeLinejoin="round" x1="69.5" x2="69.5" y1="126.5" y2="130.5" />
          <line stroke="#939eae" strokeLinecap="round" strokeLinejoin="round" x1="71.5" x2="67.5" y1="128.5" y2="128.5" />
        </g>
        <g className={classes['TutorialObstacle-Img-Stars-Star']}>
          <line stroke="#939eae" strokeLinecap="round" strokeLinejoin="round" x1="55.5" x2="55.5" y1="78.5" y2="82.5" />
          <line stroke="#939eae" strokeLinecap="round" strokeLinejoin="round" x1="57.5" x2="53.5" y1="80.5" y2="80.5" />
        </g>
        <g className={classes['TutorialObstacle-Img-Stars-Star']}>
          <line stroke="#4F5a66" strokeLinecap="round" strokeLinejoin="round" x1="28.5" x2="28.5" y1="158.5" y2="162.5" />
          <line stroke="#4F5a66" strokeLinecap="round" strokeLinejoin="round" x1="30.5" x2="26.5" y1="160.5" y2="160.5" />
        </g>
        <g className={classes['TutorialObstacle-Img-Stars-Star']}>
          <line stroke="#939eae" strokeLinecap="round" strokeLinejoin="round" x1="71.5" x2="71.5" y1="175.5" y2="179.5" />
          <line stroke="#939eae" strokeLinecap="round" strokeLinejoin="round" x1="73.5" x2="69.5" y1="177.5" y2="177.5" />
        </g>
        <g className={classes['TutorialObstacle-Img-Stars-Star']}>
          <line stroke="#4F5a66" strokeLinecap="round" strokeLinejoin="round" x1="44.5" x2="44.5" y1="33.5" y2="37.5" />
          <line stroke="#4F5a66" strokeLinecap="round" strokeLinejoin="round" x1="46.5" x2="42.5" y1="35.5" y2="35.5" />
        </g>
        <g className={classes['TutorialObstacle-Img-Stars-Star']}>
          <line stroke="#939eae" strokeLinecap="round" strokeLinejoin="round" x1="98.5" x2="98.5" y1="75.5" y2="79.5" />
          <line stroke="#939eae" strokeLinecap="round" strokeLinejoin="round" x1="100.5" x2="96.5" y1="77.5" y2="77.5" />
        </g>
        <g className={classes['TutorialObstacle-Img-Stars-Star']}>
          <line stroke="#939eae" strokeLinecap="round" strokeLinejoin="round" x1="96.5" x2="96.5" y1="2.5" y2="6.5" />
          <line stroke="#939eae" strokeLinecap="round" strokeLinejoin="round" x1="98.5" x2="94.5" y1="4.5" y2="4.5" />
        </g>
        <g className={classes['TutorialObstacle-Img-Stars-Star']}>
          <line stroke="#939eae" strokeLinecap="round" strokeLinejoin="round" x1="542.5" x2="542.5" y1="133.5" y2="137.5" />
          <line stroke="#939eae" strokeLinecap="round" strokeLinejoin="round" x1="544.5" x2="540.5" y1="135.5" y2="135.5" />
        </g>
        <circle className={classes['TutorialObstacle-Img-Stars-Star']} cx="500.5" cy="161.5" fill="none" r="2" stroke="#4F5a66" />
        <circle className={classes['TutorialObstacle-Img-Stars-Star']} cx="555.5" cy="99.5" fill="none" r="2" stroke="#4F5a66" />
        <circle className={classes['TutorialObstacle-Img-Stars-Star']} cx="532.5" cy="70.5" fill="none" r="2" stroke="#939eae" />
        <circle className={classes['TutorialObstacle-Img-Stars-Star']} cx="569.5" cy="180.5" fill="none" r="2" stroke="#939eae" />
        <circle className={classes['TutorialObstacle-Img-Stars-Star']} cx="103.5" cy="164.5" fill="none" r="2" stroke="#939eae" />
        <circle className={classes['TutorialObstacle-Img-Stars-Star']} cx="23.5" cy="106.5" fill="none" r="2" stroke="#f0f0f0" />
        <circle className={classes['TutorialObstacle-Img-Stars-Star']} cx="0.5" cy="67.5" fill="none" r="2" stroke="#939eae" />
        <circle className={classes['TutorialObstacle-Img-Stars-Star']} cx="474.5" cy="2.5" fill="none" r="2" stroke="#f0f0f0" />
      </svg>
      {/* prettier-ignore-end */}
      <TutorialCard className={classes['TutorialObstacle-Img-Card']} tutorial={tutorial} />
    </div>
  </div>
);

TutorialObstacle.propTypes = {
  body: PropTypes.string,
  children: PropTypes.any,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  closeTutorialModal: PropTypes.func,
  heading: PropTypes.string,
  tutorial: TutorialProp.isRequired,
};

TutorialObstacle.defaultProps = {
  tutorial: {
    backgroundColor: '#edb33e',
    description: 'Use our Quotes API and Fare API to get estimated fares in advance.',
    hero: false,
    emoji: '🦉',
    published: true,
    stepCount: 3,
    title: 'Request a Quote in Advance',
  },
};

export default TutorialObstacle;
