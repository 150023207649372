import { format } from 'date-fns';

/**
 * Formats date strings to a `datetime-local` friendly format that can be used
 * with `<input type="datetime-local" />` input fields.
 * @example
 * ```tsx
 * <Input type='datetime-local' value={formatDateTimeLocal('2024-06-06T04:45:05.868Z')
 * ```
 * @link https://developer.mozilla.org/en-US/docs/Web/HTML/Date_and_time_formats#local_date_and_time_strings
 */
export function formatDateTimeLocal(date?: string) {
  if (!date) return '';
  return format(new Date(date), "yyyy-MM-dd'T'HH:mm");
}
