import { getSupportedLanguages } from '@readme/oas-to-snippet/languages';
import { uppercase } from '@readme/syntax-highlighter';
import httpsnippetClientAPIPlugin from 'httpsnippet-client-api';
import Cookie from 'js-cookie';

const defaultLanguage = () => {
  const SUPPORTED_LANGUAGES = getSupportedLanguages({
    plugins: [httpsnippetClientAPIPlugin],
  });

  const language = Cookie.get('readme_language');

  if (language && SUPPORTED_LANGUAGES[language]) {
    return {
      code: '',
      language,
      highlightedSyntax: SUPPORTED_LANGUAGES[language].highlight,
      name: uppercase(language),
    };
  }

  return {
    code: '',
    language: 'curl',
    highlightedSyntax: 'shell',
    name: 'cURL',
  };
};

const DEFAULT_ENDPOINT = {
  method: '',
  slug: '',
  title: '',
};

const DEFAULT_SNIPPET = {
  endpoint: DEFAULT_ENDPOINT,
  codeOptions: [defaultLanguage()],
};

const DEFAULT_STEP = {
  title: '',
  body: '',
  isOpen: true,
  lineNumbers: [''],
};

const DEFAULT_TUTORIAL = {
  backgroundColor: '#018FF4',
  emoji: '🦉',
  response: '{"success":true}',
  referenceDisplay: [],
  steps: [DEFAULT_STEP],
  snippet: DEFAULT_SNIPPET,
  title: 'Recipe Title',
  description: 'Recipe Description',
};

export { DEFAULT_ENDPOINT, DEFAULT_SNIPPET, DEFAULT_STEP, DEFAULT_TUTORIAL, defaultLanguage };
