import { Owlmoji as OwlmojiMaps } from '@readme/markdown';
import React from 'react';

interface Props {
  children: string;
  className?: string;
}

interface OwlmojiType {
  description: string;
  names: string[];
}

// TODO: move these to RDMD
export const owlmojiByName: Record<string, OwlmojiType> = OwlmojiMaps.owlmoji.reduce((memo, meta: OwlmojiType) => {
  meta.names.forEach(name => {
    memo[name] = meta;
  });
  return memo;
}, {});

// TODO: move these to RDMD
export const nameByOwlmoji: Record<string, string> = Object.entries<string>(OwlmojiMaps.nameToEmoji).reduce(
  (memo, [name, emoji]) => {
    memo[emoji] = name;
    return memo;
  },
  {},
);

const Owlmoji = ({ children, ...rest }: Props) => {
  const name = children?.replaceAll(/^:|:$/g, '');

  if (!name) {
    return null;
  }

  switch (OwlmojiMaps.kind(name)) {
    case 'owlmoji':
      return <img alt={name} loading="lazy" src={`/public/img/emojis/${name}.png`} {...rest} />;
    case 'gemoji':
      return (
        <span aria-label={owlmojiByName[name].description} role="img" {...rest}>
          {OwlmojiMaps.nameToEmoji[name]}
        </span>
      );
    default:
      if (name in nameByOwlmoji) {
        return (
          <span aria-label={owlmojiByName[nameByOwlmoji[name]].description} role="img" {...rest}>
            {name}
          </span>
        );
      }

      return typeof name === 'string' ? (
        <span {...rest}>{name}</span>
      ) : (
        <span role="img" {...rest}>
          {name}
        </span>
      );
  }
};

export default Owlmoji;
