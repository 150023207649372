import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import React from 'react';

import { PermissionContext } from '../../PermissionContext';

import classes from './style.module.scss';

const syntaxHighlighter = typeof window !== 'undefined' ? require('@readme/syntax-highlighter/dist').default : () => {};

export default function TutorialResponse({ response, updateTutorial }) {
  const handleChange = (value, { origin }, readOnly) => {
    if (['+input', 'paste', '+delete'].includes(origin) && !readOnly) {
      updateTutorial({ response: value });
    }
  };

  const onChange = process.env.NODE_ENV === 'test' ? handleChange : debounce(handleChange, 300);
  const codeMirrorOpts = readOnly => {
    if (readOnly) {
      return {
        className: 'CodeEditor-Input CodeEditor-Input_readonly',
        dark: true,
        highlightMode: true,
        tokenizeVariables: true,
      };
    }
    return {
      className: 'CodeEditor-Input',
      dark: true,
      editable: true,
    };
  };

  return (
    <PermissionContext.Consumer>
      {readOnly => (
        <React.Fragment>
          {!readOnly && (
            <i
              className={`${classes['TutorialResponse-Tooltip']} fa fa-question-circle`}
              data-tooltip="Leave the response section empty to hide it from your users"
              data-tooltip-position="left"
            />
          )}
          {syntaxHighlighter(response, 'json', codeMirrorOpts(readOnly), {
            onChange: (editor, data, value) => onChange(value, data, readOnly),
          })}
        </React.Fragment>
      )}
    </PermissionContext.Consumer>
  );
}

TutorialResponse.propTypes = {
  response: PropTypes.string,
  updateTutorial: PropTypes.func,
};

TutorialResponse.defaultProps = {
  response: '',
};
