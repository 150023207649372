import type { $TSFixMe } from '@readme/iso';
import type { ForwardedRef, SyntheticEvent } from 'react';

import React, { useState, forwardRef } from 'react';

import classy from '@core/utils/classy';

import { nameByOwlmoji } from '@ui/Owlmoji';

import './style.scss';

export type Props = React.HTMLAttributes<HTMLDivElement> & {
  children?: $TSFixMe;
  circular?: boolean;
  className?: string;
  hexColor?: string;
  imgAlt?: string;
  imgSrc?: string;
  onImgError?: (e: SyntheticEvent) => void;
  size?: 'lg' | 'md' | 'sm' | 'xl' | 'xs';
};

function Avatar(
  { children, circular, className, hexColor = '#f0f0f0', size = 'md', imgAlt, imgSrc, onImgError, ...attrs }: Props,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const [isImage, setIsImage] = useState(Boolean(imgSrc));

  let text = (typeof children?.valueOf() !== 'string' ? children?.props.children : children) || '';
  const isEmoji = !isImage && text in nameByOwlmoji;

  /**
   * Because emoji like 👩‍🏫 can be comprised of multiple unicode characters we don't want to
   * trim them down because they'll get corrupted.
   *
   * @example '👩‍🏫'.split('') // ['\uD83D', '\uDC69', '‍', '\uD83C', '\uDFEB']
   */
  if (!isEmoji) {
    text = text.substring(0, 2);
  }

  const handleImgError = (e: SyntheticEvent) => {
    if (onImgError) {
      onImgError(e);
    }
    setIsImage(false);
  };

  const avatarClasses = classy(
    'Avatar',
    `Avatar_${size}`,
    className,
    circular && 'Avatar_circular',
    isEmoji && 'Avatar_emoji',
  );

  return (
    <div ref={ref} {...attrs} className={avatarClasses} style={{ background: hexColor }}>
      {isImage ? (
        <img alt={imgAlt || ''} className="Avatar-Img" onError={handleImgError} src={imgSrc} />
      ) : (
        <span className="Avatar-Text">{text}</span>
      )}
    </div>
  );
}
export default forwardRef(Avatar);
