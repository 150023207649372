import type { ForwardedRef } from 'react';

import React, { useEffect, useCallback, useState, forwardRef } from 'react';

import classy from '@core/utils/classy';

import Button from '@ui/Button';

import './style.scss';

export type InputProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'prefix' | 'size' | 'value'> & {
  isMinimal?: boolean;
  onBlur?: (ev: React.FocusEvent<HTMLInputElement>) => void;
  onClear?: () => void;
  /**
   * Display inline content within the input before the field text
   */
  prefix?: React.ReactNode;
  size?: 'lg' | 'md' | 'sm' | 'xs';
  /**
   * Display inline content within the input after the field text
   */
  suffix?: React.ReactNode;

  value?: React.InputHTMLAttributes<HTMLInputElement>['value'] | null;
  /**
   * Input will be wrapped in a div if onClear, prefix, or suffix props are provided.
   * This prop allows you to pass a class name to that wrapper div.
   */
  wrapperClassName?: string;
};

const buttonSizeMap = { xs: 'xxs', sm: 'xs', md: 'sm', lg: 'md' };

function Input(
  {
    className,
    isMinimal = false,
    onBlur,
    onChange,
    onClear,
    prefix,
    size = 'md',
    suffix,
    type = 'text',
    value: providedValue = '',
    wrapperClassName,
    ...attributes
  }: InputProps,
  ref: ForwardedRef<HTMLInputElement>,
) {
  const [value, setValue] = useState(providedValue);
  const [isTouched, setIsTouched] = useState(false);

  useEffect(() => {
    setValue(providedValue);
  }, [providedValue]);

  const handleClear = useCallback(() => {
    setValue('');
    if (typeof onClear === 'function') onClear();
  }, [onClear]);

  const handleBlur = useCallback(
    (ev: React.FocusEvent<HTMLInputElement>) => {
      setIsTouched(true);
      if (typeof onBlur === 'function') onBlur(ev);
    },
    [onBlur],
  );

  const handleChange = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      setValue(ev.target.value);
      if (typeof onChange === 'function') onChange(ev);
    },
    [onChange],
  );

  const hasParent = onClear || prefix || suffix;
  const isClearable = !!onClear && !!value;
  const Tag = hasParent ? 'div' : React.Fragment;

  return (
    <Tag
      {...(hasParent
        ? {
            className: classy(
              'InputParent',
              `InputParent_${size}`,
              onClear && 'InputParent_isClearable',
              prefix && 'InputParent_isPrefixed',
              suffix && 'InputParent_isSuffixed',
              wrapperClassName,
            ),
          }
        : {})}
    >
      {!!prefix && <div className="InputParent-prefix">{prefix}</div>}
      <input
        ref={ref}
        {...(type === 'password' ? { autoComplete: 'off' } : {})}
        {...attributes}
        className={classy(
          'Input',
          `Input_${size}`,
          isTouched && 'Input_touched',
          isMinimal && 'Input_minimal',
          className,
        )}
        onBlur={handleBlur}
        onChange={handleChange}
        spellCheck="false"
        type={type}
        value={value || ''}
      />

      {!!(suffix || isClearable) && (
        <div className="InputParent-suffix">
          {suffix}
          {!!isClearable && (
            <Button
              aria-label="Clear input"
              className="InputParent-clearBtn"
              kind="minimum"
              onClick={handleClear}
              size={buttonSizeMap[size] as 'md' | 'sm' | 'xs'}
              text
            >
              <i className="icon-x-circle" />
            </Button>
          )}
        </div>
      )}
    </Tag>
  );
}

export default forwardRef(Input);
export * from './util';
