import rdmd from '@readme/markdown';
import PropTypes from 'prop-types';
import React, { useReducer } from 'react';

import classy from '@core/utils/classy';

import Button from '@ui/Button';
import Flex from '@ui/Flex';
import Input from '@ui/Input';
import Textarea from '@ui/Textarea';

import { PermissionContext } from '../../PermissionContext';
import { TutorialStepProp } from '../../proptypes/tutorials';

import classes from './style.module.scss';

export default function TutorialStep({
  step,
  index,
  lastIndex,
  shiftOrder,
  navStep,
  handleClick,
  deleteStep,
  updateStep,
  selectedTab,
}) {
  const { body, className, title, isOpen } = step;

  const tutorialStepClasses = [
    className,
    classes.TutorialStep,
    isOpen ? classes.TutorialStep_open : classes.TutorialStep_close,
  ];

  const [dirtyInput, setInputState] = useReducer((prevState, state) => ({ ...prevState, ...state }), {
    title: false,
    description: false,
    lineNumbers: false,
  });

  const lineNumberRegex = /^[0-9,-]+$/;

  const handleLineChange = value => {
    if (!value || lineNumberRegex.test(value)) {
      const { lineNumbers } = step;
      lineNumbers[selectedTab] = value;
      updateStep({ lineNumbers });
      setInputState({ lineNumbers: true });
    }
  };

  const handleChange = target => {
    const { id, name, value } = target;
    const [, key] = id.split('-');
    updateStep({ [key]: value });
    setInputState({ [name]: true });
  };

  const onClick = event => {
    const { target } = event;
    if (!isOpen && target.nodeName !== 'I') handleClick();
  };

  return (
    <PermissionContext.Consumer>
      {readOnly => (
        <section
          className={classy(...tutorialStepClasses)}
          onClick={onClick}
          onKeyDown={onClick}
          role={readOnly ? 'button' : 'section'}
          tabIndex={readOnly ? 0 : null}
        >
          <header className={classes['TutorialStep-Header']}>
            {readOnly ? (
              <span className={classes['TutorialStep-Header-Title']}>{`${
                readOnly ? `${index + 1}. ` : ''
              }${title}`}</span>
            ) : (
              <Input
                key={`tutorialStep-title-${index}`}
                autoComplete="off"
                autoFocus
                className={[
                  classes['TutorialStep-Header-Input'],
                  dirtyInput.title ? 'Input_dirty' : 'Input_clean',
                ].join(' ')}
                id={`tutorialStep-title-${index}`}
                name="title"
                onChange={e => handleChange(e.target)}
                placeholder={'Step Name'}
                readOnly={readOnly || !isOpen}
                required
                title={`${readOnly ? `${index + 1}. ` : ''}${title}`}
                value={`${readOnly ? `${index + 1}. ` : ''}${title}`}
              />
            )}
            {!readOnly && (
              <React.Fragment>
                <Button
                  bem={{ shale_text: true }}
                  className={classes['TutorialStep-Header-Button']}
                  disabled={index === 0}
                  onClick={() => shiftOrder(-1)}
                  size="sm"
                  text
                >
                  <i className="fa fa-arrow-circle-up" />
                </Button>
                <Button
                  bem={{ shale_text: true }}
                  className={classes['TutorialStep-Header-Button']}
                  disabled={index === lastIndex}
                  onClick={() => shiftOrder(1)}
                  size="sm"
                  text
                >
                  <i className="fa fa-arrow-circle-down" />
                </Button>
                {!!isOpen && (
                  <Button
                    bem={{ red_text: true }}
                    className={[classes['TutorialStep-Header-Button'], classes['TutorialStep-Header-Button_red']].join(
                      ' ',
                    )}
                    disabled={lastIndex === 0}
                    onClick={() => deleteStep(index)}
                    size="sm"
                  >
                    <i className="icon icon-trash-2" />
                  </Button>
                )}
              </React.Fragment>
            )}
          </header>
          <div className={classes['TutorialStep-Body']}>
            {readOnly ? (
              <div className={`rm-Markdown markdown-body ${classes['TutorialStep-Body-Description']}`}>
                {rdmd(body)}
              </div>
            ) : (
              <Textarea
                key={`tutorialStep-body-${index}`}
                className={[
                  dirtyInput.description ? 'Textarea_dirty' : 'Textarea_clean',
                  classes['TutorialStep-Body-Textarea'],
                ].join(' ')}
                id={`tutorialStep-body-${index}`}
                name="description"
                onChange={event => handleChange(event.target)}
                placeholder={'Description'}
                readOnly={readOnly}
                resize={readOnly ? 'none' : 'vertical'}
                value={body}
              />
            )}
          </div>
          {!readOnly && !!isOpen && (
            <div className={classes['TutorialStep-LineNumbers']}>
              <Flex align="center" gap="xs">
                <span>Highlight Lines</span>
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label>
                  <input
                    key={`tutorialStep-lineNumbers-${index}`}
                    autoComplete="off"
                    className={[
                      'Input',
                      'Input_sm',
                      classes['TutorialStep-lineNumbers-Input'],
                      dirtyInput.lineNumbers ? 'Input_dirty' : 'Input_clean',
                    ].join(' ')}
                    id={`tutorialStep-lineNumbers-${index}`}
                    name="lineNumbers"
                    onChange={e => handleLineChange(e.target.value)}
                    placeholder="1,4,7-9"
                    readOnly={readOnly || !isOpen}
                    value={step.lineNumbers[selectedTab]}
                  />
                  <i
                    className={['fa fa-question', classes['TutorialStep-LineNumbers-Tooltip']].join(' ')}
                    data-tooltip="Highlight multiple lines with commas and hyphens"
                    data-tooltip-position="left"
                  />
                </label>
              </Flex>
            </div>
          )}
          {!!readOnly && !!isOpen && index !== lastIndex && (
            <footer className={classes['TutorialStep-Footer']}>
              <Button
                bem={{ shale_text: true }}
                className={classes['TutorialStep-Footer-Button']}
                onClick={() => navStep(1)}
                text
              >
                Next <i className="fa fa-arrow-circle-down" />
              </Button>
            </footer>
          )}
        </section>
      )}
    </PermissionContext.Consumer>
  );
}

TutorialStep.propTypes = {
  deleteStep: PropTypes.func,
  handleClick: PropTypes.func,
  index: PropTypes.number,
  lastIndex: PropTypes.number,
  navStep: PropTypes.func,
  selectedTab: PropTypes.number,
  shiftOrder: PropTypes.func,
  step: TutorialStepProp,
  updateStep: PropTypes.func,
};

TutorialStep.defaultProps = {
  step: {},
};
