import { Owlmoji } from '@readme/markdown';
import PropTypes from 'prop-types';
import React from 'react';

import Avatar from '@ui/Avatar';
import Box from '@ui/Box';
import Button from '@ui/Button';
import ColorPicker from '@ui/ColorPicker';
import Dropdown from '@ui/Dropdown';
import EmojiPicker from '@ui/EmojiPicker';
import Flex from '@ui/Flex';
import Input from '@ui/Input';
import Textarea from '@ui/Textarea';

import { ApiEndpointProp } from '../../proptypes/apiEndpoints';
import { TutorialProp } from '../../proptypes/tutorials';
import TutorialReferencePicker from '../TutorialReferencePicker';

import classes from './style.module.scss';

const TutorialReview = ({ debounceInputChange, updateTutorial, tutorial, endpoints }) => {
  const setReferenceSelection = id => {
    const { referenceDisplay } = tutorial;

    const index = referenceDisplay.findIndex(ref => ref.toString() === id.toString());
    if (index >= 0) referenceDisplay.splice(index, 1);
    else referenceDisplay.push(id);

    updateTutorial({ referenceDisplay });
  };

  return (
    <section className={classes.TutorialReview}>
      <fieldset className={`${classes['TutorialReview-Fieldset']} ${classes['TutorialReview-Fieldset_emojiPicker']}`}>
        <Flex gap="sm" justify="start">
          <Dropdown sticky>
            <Avatar
              className={[
                classes['TutorialReview-Fieldset-Avatar'],
                !tutorial.emoji && classes['TutorialReview-Fieldset-Avatar_empty'],
                !tutorial.backgroundColor && classes['TutorialReview-Fieldset-Avatar_nobg'],
              ].join(' ')}
              hexColor={tutorial.backgroundColor}
            >
              {tutorial.emoji || 'NA'}
            </Avatar>
            <div className={classes['TutorialReview-Fieldset-ClearWrapper']}>
              <Box className={classes['TutorialReview-Fieldset-Box']} kind="pop">
                <EmojiPicker onClick={e => updateTutorial({ emoji: Owlmoji.nameToEmoji[e] ?? null })} owlmoji={false} />
              </Box>
            </div>
          </Dropdown>
          <ColorPicker
            className={classes['TutorialReview-Fieldset-Color']}
            color={tutorial.backgroundColor}
            onChange={color => debounceInputChange({ backgroundColor: color })}
          />
        </Flex>
      </fieldset>
      {endpoints.length > 0 && (
        <fieldset
          className={[classes['TutorialReview-Fieldset'], classes['TutorialReview-Fieldset_endpoints']].join(' ')}
        >
          <Flex align="center" className={classes['TutorialReview-Fieldset-Header']} justify="between">
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label className={classes['TutorialReview-Fieldset-Label']}>Show in References</label>
            <Button bem={{ red_text: true }} onClick={() => updateTutorial({ referenceDisplay: [] })} size="sm">
              Clear All
            </Button>
          </Flex>
          <TutorialReferencePicker
            endpoints={endpoints}
            referenceDisplay={tutorial.referenceDisplay}
            setReferenceSelection={setReferenceSelection}
          />
        </fieldset>
      )}
      <fieldset className={classes['TutorialReview-Fieldset']}>
        <Input
          className={classes['TutorialReview-Fieldset-Input']}
          onChange={e => debounceInputChange({ title: e.target.value })}
          placeholder="Recipe Name"
          required
          value={tutorial.title}
        />
      </fieldset>
      <fieldset className={classes['TutorialReview-Fieldset']}>
        <Textarea
          className={classes['TutorialReview-Fieldset-Textarea']}
          onChange={e => debounceInputChange({ description: e.target.value })}
          placeholder="Recipe Description"
          value={tutorial.description}
        />
      </fieldset>
    </section>
  );
};

TutorialReview.propTypes = {
  debounceInputChange: PropTypes.func,
  endpoints: PropTypes.arrayOf(ApiEndpointProp),
  tutorial: TutorialProp,
  updateTutorial: PropTypes.func,
};

export default TutorialReview;
