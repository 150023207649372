import PropTypes from 'prop-types';

const CodeOptionProp = PropTypes.shape({
  code: PropTypes.string,
  highlightedSyntax: PropTypes.string,
  language: PropTypes.string,
  name: PropTypes.string,
});

const EndpointProp = PropTypes.shape({
  method: PropTypes.string,
  page: PropTypes.string,
  slug: PropTypes.string,
  title: PropTypes.string,
});

const StepSnippetProp = PropTypes.shape({
  codeOptions: PropTypes.arrayOf(CodeOptionProp),
  endpoint: EndpointProp,
});

const TutorialStepProp = PropTypes.shape({
  body: PropTypes.string,
  isOpen: PropTypes.bool,
  lineNumbers: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
});

const TutorialProp = PropTypes.shape({
  _id: PropTypes.string,
  backgroundColor: PropTypes.string,
  className: PropTypes.string,
  description: PropTypes.string,
  emoji: PropTypes.string,
  hero: PropTypes.bool,
  published: PropTypes.bool,
  referenceDisplay: PropTypes.arrayOf(PropTypes.string),
  response: PropTypes.string,
  slug: PropTypes.string,
  snippet: StepSnippetProp,
  stepCount: PropTypes.number,
  steps: PropTypes.arrayOf(TutorialStepProp),
  title: PropTypes.string,
  version: PropTypes.string,
});

export { CodeOptionProp, EndpointProp, StepSnippetProp, TutorialStepProp, TutorialProp };
