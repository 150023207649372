import type { RecipeReadCollectionType, CreateRecipeType, RecipeReadType } from '@readme/api/src/mappings/recipe/types';
import type { TutorialSchema } from '@readme/backend/models/tutorial/types';

export type LegacyRecipeType = Pick<
  TutorialSchema,
  // Grabbing only the properties the frontend cares about
  | 'backgroundColor'
  | 'description'
  | 'emoji'
  | 'published'
  | 'response'
  | 'slug'
  | 'snippet'
  | 'stepCount'
  | 'steps'
  | 'title'
>;

/**
 * Converts a recipe representation from the APIv2 format to the legacy format.
 */
export function apiNextRecipeToLegacy(data: RecipeReadType['data']): LegacyRecipeType {
  const { appearance, content, ...properties } = data;

  return {
    ...properties,
    description: properties.description as string,
    backgroundColor: appearance.background_color as string,
    emoji: appearance.emoji as string,
    snippet: {
      codeOptions: content.snippet.code_options.map(option => ({
        highlightedSyntax: option.highlighted_syntax || '',
        code: option.code || '',
        language: option.language || '',
        name: option.name || '',
      })),
      endpoint: {
        method: '', // Stubbing this out for now
        page: '', // Stubbing this out for now
        slug: '', // Stubbing this out for now
        title: '', // Stubbing this out for now
      },
    },
    steps: content.steps.map(step => ({
      title: step.title,
      body: step.body || '',
      lineNumbers: step.line_numbers,
    })),
    stepCount: content.steps.length,
    response: content.response || '',
    published: data.privacy.view === 'public',
  };
}

/**
 * Converts a recipe representation from the legacy format to the APIv2 format.
 */
export function legacyRecipeToApiNext(data: LegacyRecipeType): CreateRecipeType {
  return {
    appearance: {
      background_color: data.backgroundColor,
      emoji: data.emoji,
    },
    content: {
      snippet: {
        code_options: data.snippet?.codeOptions?.map(option => ({
          code: option.code,
          language: option.language,
          name: option.name,
          highlighted_syntax: option.highlightedSyntax,
        })),
      },
      steps: data.steps?.map(step => ({
        title: step.title,
        body: step.body,
        line_numbers: step.lineNumbers,
      })),
      response: data.response,
    },
    description: data.description,
    privacy: {
      view: data.published ? 'public' : 'anyone_with_link',
    },
    title: data.title,
  };
}

/**
 * Normalizes the response for the `deleteTutorial` API action from the APIv2 response payload to the legacy response format.
 */
export function normalizeDeleteTutorialResponse() {
  return { success: true };
}

/**
 * Normalizes the response for the `getTutorial` API action from the APIv2 response payload to the legacy response format.
 */
export function normalizeGetTutorialResponse(response: RecipeReadType) {
  return {
    tutorial: apiNextRecipeToLegacy(response.data),
  };
}

/**
 * Normalizes the response for the `getTutorials` API action from the APIv2 response payload to the legacy response format.
 */
export function normalizeGetTutorialsResponse(response: RecipeReadCollectionType) {
  const tutorials = response.data.map(apiNextRecipeToLegacy);

  // For APIv2 recipes the hero is the first recipe in the array
  return {
    hero: tutorials[0],
    tutorials: tutorials.map((tutorial, i) => ({
      ...tutorial,
      hero: i === 0,
    })),
    tutorialOnboarding: {
      published: !!tutorials.length,
      endpoints: false, // Stubbing this out for now
    },
  };
}

/**
 * Normalizes the response for the `createTutorial` and `updateTutorial` API actions from the legacy request body payload
 * to the APIv2 request body payload.
 */
export function normalizeUpsertTutorialRequestBody(data): CreateRecipeType {
  return legacyRecipeToApiNext(data);
}

/**
 * Normalizes the response for the `createTutorial` and `updateTutorial` API actions from the APIv2 response payload to
 * the legacy response format.
 */
export function normalizeUpsertTutorialResponse(response: RecipeReadType) {
  return {
    tutorial: apiNextRecipeToLegacy(response.data),
    success: true,
    // this flag is only used to open the modal when navigating to a tutorial, so setting it to true equates
    // to "not a GET request"
    tutorialPublishToggled: true,
  };
}
