import PropTypes from 'prop-types';

const PageProp = PropTypes.shape({
  _id: PropTypes.string,
  api: PropTypes.shape({
    apiSetting: PropTypes.string,
    method: PropTypes.string,
  }),
  path: PropTypes.string,
  slug: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
});

const ApiEndpointProp = PropTypes.shape({
  categoryId: PropTypes.string,
  children: PropTypes.arrayOf(PageProp),
  title: PropTypes.string,
});

export { ApiEndpointProp };
