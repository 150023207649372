import React from 'react';

import './style.scss';

const MenuHeader = ({ className = '', ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={`Menu-Header${className ? ` ${className}` : ''}`} {...props}>
    {props.children}
  </div>
);

export default React.memo(MenuHeader);
